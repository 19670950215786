import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, Component, Inject } from '@angular/core';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslatableComponent, TranslationService, TranslationStore } from '@prekog/pw-common';
import { Observable } from 'rxjs';
import { GetTranslationUseCase } from './usecases/translation-usecase/read-translation-usecase';
import { GetTranslationRequest } from './usecases/translation-usecase/read-translation-request';
import { LoadingService } from '@prekog/pw-common';
import { Router} from '@angular/router';
import { Material } from './publication/material';
import { LanguageService } from '@prekog/pw-common';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetSiteConfigurationResponse } from './getsiteconfigurationresponse';
import { SiteConfiguration } from './siteconfiguration';

@Component({
    selector: 'pw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends TranslatableComponent implements AfterViewChecked, AfterViewInit {
    cookieValue = '';

    facebookImgUrl = '';

    facebookUrl = 'https://www.facebook.com/profile.php?id=100089168181347';

    loading = false;

    logoUrl = '';

    nameOfTheSite = 'Multiverzum Kiadó';

    products?: Material[];

    title = 'Multiverzum Kiadó';

    production = false;

    downloadFileUrl = "";

    aszfUrl = "";

    dataManagamentUrl = "";

    simplePayLogo = '';

    colorOfMobileSidenav = 'lightgreen';

    configuratorUrl = '';

    email: string | undefined;

    address: string | undefined;

    phoneNumber: string | undefined;

    getSiteconfigurationUrl = '/websiteconfigurator/get-site-configuration';

    openingHours: string | undefined;

    constructor(
        private _translateService: TranslationService,
        private _http: HttpClient,
        private _readTranslationfileUseCase: GetTranslationUseCase,
        private _loadingService: LoadingService,
        public router: Router,
        private _apiRequestService: ApiRequestService,
        private _languageService: LanguageService,
        @Inject('PRODUCTION') private _production: boolean,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorUrl: string,
        private _toastMessageService: ToastmessageService,

    ) {
        super(_translateService, _http);
        this.production = _production;
        this.downloadFileUrl = _downloadFileUrl;
        this.simplePayLogo = this.downloadFileUrl+'/simplepaylogo'
        this.facebookImgUrl = this.downloadFileUrl+'/facebooklog';
        this.logoUrl = this.downloadFileUrl+'/multiverzumlogo'
        this.configuratorUrl = _configuratorUrl;

    }

    ngAfterViewInit(): void {

    }

    ngAfterViewChecked(): void {
        this.loading = this._loadingService.visibility.getValue();
    }

    override readTranslations(http: HttpClient, language: string): Observable<TranslationStore> {
        const request: GetTranslationRequest = { url: './assets/i18n/' + language + '.json' };
        this._readTranslationfileUseCase.execute(request) as unknown as Observable<TranslationStore>;
        return this._http.get<TranslationStore>('./assets/i18n/' + language + '.json');
    }

    override onInit(): void {
    //this.aszfUrl = this.downloadFileUrl+"/aszf";
    //this.dataManagamentUrl = this.downloadFileUrl+"/adatkezeles";
    this.getSiteConfiguration();

    }

    getSiteConfiguration() {
        this._apiRequestService
            .sendRequest<{}, GetSiteConfigurationResponse>(this.configuratorUrl, true, {}, true, this.getSiteconfigurationUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();
                    });
                })
            )
            .subscribe((response: GetSiteConfigurationResponse) => {
                const siteConfigurationList = response.siteConfigurationList as SiteConfiguration[];
                const siteConfigurationUrlList = response.siteConfigurationIsUrlList as SiteConfiguration[];
                siteConfigurationList.forEach((siteconfig) => {
                    if (siteconfig.configurationKey === 'email') {
                        sessionStorage.setItem('email', JSON.stringify(siteconfig.configurationValue));
                    }
                    if (siteconfig.configurationKey === 'privacy_policy_url') {
                        sessionStorage.setItem('privacy_policy_url', JSON.stringify(siteconfig.configurationValue));
                    }
                    if (siteconfig.configurationKey === 'address') {
                        sessionStorage.setItem('address', JSON.stringify(siteconfig.configurationValue));
                    }
                    if (siteconfig.configurationKey === 'cellphone_number') {
                        sessionStorage.setItem('cellphone_number', JSON.stringify(siteconfig.configurationValue));
                    }
                    if (siteconfig.configurationKey === 'logo_url') {
                        sessionStorage.setItem('logo_url', JSON.stringify(siteconfig.configurationValue));

                    }
                    if(siteconfig.configurationKey === 'aszf_url') {
                        sessionStorage.setItem('aszf_url', JSON.stringify(siteconfig.configurationValue));
                    }
                    if(siteconfig.configurationKey === 'opening_hours') {
                        sessionStorage.setItem('opening_hours', JSON.stringify(siteconfig.configurationValue));
                    }
                });

                siteConfigurationUrlList.forEach((siteconfig)=> {
                    if (siteconfig.configurationKey === 'privacy_policy_url') {
                        sessionStorage.setItem('privacy_policy_url', JSON.stringify(siteconfig.configurationValue));
                    }
                    if (siteconfig.configurationKey === 'logo_url') {
                       sessionStorage.setItem('logo_url', JSON.stringify(siteconfig.configurationValue));

                    }
                     if(siteconfig.configurationKey === 'aszf_url') {
                        sessionStorage.setItem('aszf_url', JSON.stringify(siteconfig.configurationValue));
                    }
                });

                if(sessionStorage.getItem('logo_url')) {
                   this.logoUrl = JSON.parse(sessionStorage.getItem('logo_url') as string);
                }
                if(sessionStorage.getItem('email')) {
                    this.email = JSON.parse(sessionStorage.getItem('email') as string);
                 }
                 if(sessionStorage.getItem('privacy_policy_url')) {
                    this.dataManagamentUrl = JSON.parse(sessionStorage.getItem('privacy_policy_url') as string);
                 }
                 if(sessionStorage.getItem('address')) {
                    this.address = JSON.parse(sessionStorage.getItem('address') as string);
                 }
                 if(sessionStorage.getItem('cellphone_number')) {
                    this.phoneNumber = JSON.parse(sessionStorage.getItem('cellphone_number') as string);
                 }
                 if(sessionStorage.getItem('aszf_url')) {
                    this.aszfUrl = JSON.parse(sessionStorage.getItem('aszf_url') as string);
                 }
                 if(sessionStorage.getItem('opening_hours')) {
                    this.openingHours = JSON.parse(sessionStorage.getItem('opening_hours') as string);
                 }
            });
    }


    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

}
