import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BooksComponent } from './books/books.component';
import { PublicationComponent } from './publication/publication.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
    LanguageModule,
    FooterModule,
    BreadcrumbModule,
    HeadermoduleModule,
    TranslationModule,
    SharedModule,
    ShoppingcartModule,
    IntroductionModule,
    LoginModule,
    ToastmessageModule,
    LogLevel,
    SlideshowModule,
    FilterModule



} from '@prekog/pw-common';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BlogComponent } from './blog/blog.component';
import { BlogsComponent } from './blogs/blogs.component';
import localeHu from '@angular/common/locales/hu';
import localeHuExtra from '@angular/common/locales/extra/hu';
import { registerLocaleData } from '@angular/common';
import { BlogSlideshowComponent } from './blog-slideshow/blog-slideshow.component';
import { environment } from 'src/environments/environment';
import { BookExtractPopupComponent } from './book-extract-popup/book-extract-popup.component';
import { SpecialofferComponent } from './specialoffer/specialoffer.component';
import { DiscountedproductComponent } from './discountedproduct/discountedproduct.component';
import { PressarticleComponent } from './pressarticle/pressarticle.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SoonPublishedComponent } from './soon-published/soon-published.component';
import { PublishedComponent } from './published/published.component';
import { DiscountedProductsComponent } from './discounted-products/discounted-products.component';
import { PackagesComponent } from './packages/packages.component';
import { PreordersComponent } from './preorders/preorders.component';
import { HomeInfoComponent } from './home-info/home-info.component';


registerLocaleData(localeHu, 'hu-Hu', localeHuExtra);

@NgModule({
    declarations: [
        AppComponent,
        BooksComponent,
        PublicationComponent,
        HomeComponent,
        BlogComponent,
        BlogsComponent,
        BlogSlideshowComponent,
        BookExtractPopupComponent,
        SpecialofferComponent,
        DiscountedproductComponent,
        PressarticleComponent,
        SoonPublishedComponent,
        PublishedComponent,
        DiscountedProductsComponent,
        PackagesComponent,
        PreordersComponent,
        HomeInfoComponent

    ],
    entryComponents: [BooksComponent],
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true }
        },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {
            provide: LOCALE_ID,
            useValue: 'hu-HU'
        },
        { provide: 'ORDER_BACKEND_URL', useValue: environment.backendOrderUrl },
        { provide: 'STORE_BACKEND_URL', useValue: environment.backendStoreUrl },
        { provide: 'AUTH_BACKEND_URL', useValue: environment.backendAuthUrl },
        { provide: 'CONFIGURATOR_BASE_URL', useValue: environment.backendConfiguratorUrl },
        { provide: 'API_URL', useValue: environment.apiUrl },
        { provide: 'DOWNLOAD_FILE_URL', useValue: environment.downloadUrl },
        { provide: 'PRODUCTION', useValue: environment.production },

        Meta

    ],

    bootstrap: [AppComponent],
    imports: [
        ReactiveFormsModule,
        BrowserModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatDividerModule,
        MatDialogModule,
        MatSelectModule,
        MatMenuModule,
        MatFormFieldModule,
        MatCheckboxModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        TranslationModule.forRoot({ translatedLanguages: ['hu'] }),
        LanguageModule.forRoot(),
        BreadcrumbModule.forRoot(),
        FooterModule,
        HeadermoduleModule,
        AppRoutingModule,
        ShoppingcartModule,
        SharedModule.forRoot({logLevel:LogLevel.Info,enableUseCaseStatusLogs:true}), //TODO: from environment.properties
        IntroductionModule.forRoot(),
        LoginModule,
        ToastmessageModule,
        SlideshowModule,
        FilterModule
    ]
})
export class AppModule {
}
