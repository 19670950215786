<div class="publication">
    <div class="images">
        <div class="image">
            <img [src]="material?.url" />
            <div class="cart-extract">
                <div *ngIf="material?.bookExtractUrl" (click)="openPdf(material?.bookExtractUrl!)" class="extract">
                    <a target="_blank"><img src="./assets/images/materials/book_extract/beolvasas_icon.jpg" /> </a>
                    <span>Beleolvasó</span>
                </div>
                <div *ngIf="material?.isAvailable && material?.routerLink !== 'morte_taska'" class="shopping-cart-container">
                    <div *ngIf="!material?.hasPreorder" class="shopping-cart">
                        <button *ngIf="material?.quantityOfSupply !== 0" (click)="addToCart(material!)">KOSÁRBA</button>
                        <div *ngIf="material?.quantityOfSupply === 0" class="inactive">
                            <button class="inactive-button" [ngStyle]="{ opacity: '0.5', cursor: 'auto' }">
                                {{ 'shoppingCart.putIntoCart' | translate }}
                            </button>
                            <span>Elfogyott</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="material?.hasPreorder" class="shopping-cart">
                    <button (click)="addToCart(material!)" class="preorder">ELŐRENDELÉS</button>
                </div>
                <div *ngIf="material?.bookExtractUrl" (click)="openPdf(material?.bookExtractUrl!)" class="extract-desktop">
                    <a target="_blank"><img src="./assets/images/materials/book_extract/beolvasas_icon.jpg" /> </a>
                    <span>Beleolvasó</span>
                </div>
            </div>
        </div>
        <div class="sinapsis">
            <div class="content">
                <div class="author-title">
                    <h2 class="title">{{ material?.title }}</h2>
                    <span>{{ material?.subtitle }}</span>
                    <h2 class="author">{{ material?.author }}</h2>
                </div>
                <div class="shopping-cart-containerr">
                    <div class="shopping-cartt">
                        <span *ngIf="material?.oldPrice !== material?.originalPrice || material?.hasPreorder">
                            Ár a könyvön: {{ material?.oldPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </span>
                        <h3 *ngIf="material?.originalPrice! > 0 && material?.webshopPriceActive && material?.discountLimitedUntil === null">
                            Kiadói ár: {{ material?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </h3>
                        <h3 *ngIf="material?.originalPrice! > 0 && material?.webshopPriceActive && material?.discountLimitedUntil !== null">
                            Kötött ár: {{ material?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                            <div class="info">
                                <a
                                    href="https://jogkodex.hu/jsz/2024_13_torveny_6486371?ts=kozlony#pl9%C2%A7"
                                    target="_blank"
                                    [ngStyle]="{ color: 'black' }"
                                    ><mat-icon>info</mat-icon></a
                                >
                                <div [ngStyle]="{ color: 'black', 'font-weight': 'bold' }" class="text">
                                    <span [ngStyle]="{ 'font-size': '14px' }"
                                        >Az Árkötöttségi törvény alapján adható legalacsonyabb kedvezményes ár.</span
                                    >
                                </div>
                            </div>
                        </h3>
                        <h3 *ngIf="material?.originalPrice! > 0 && material?.discountPriceActive">
                            Akciós ár: {{ material?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                        </h3>
                        <h3 *ngIf="material?.hasPreorder" [ngStyle]="{ color: 'red', 'font-weight': 'bold' }">
                            Előrendelői ár: {{ material?.originalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}
                            <div class="info">
                                <a
                                    *ngIf="material?.discountLimitedUntil !== null"
                                    href="https://jogkodex.hu/jsz/2024_13_torveny_6486371?ts=kozlony#pl9%C2%A7"
                                    target="_blank"
                                    [ngStyle]="{ color: 'black' }"
                                    ><mat-icon>info</mat-icon></a
                                >
                                <div [ngStyle]="{ color: 'black', 'font-weight': 'bold' }" class="text">
                                    <span [ngStyle]="{ 'font-size': '14px' }"
                                        >Az Árkötöttségi törvény alapján adható legalacsonyabb kedvezményes ár.</span
                                    >
                                </div>
                            </div>
                        </h3>

                        <div *ngIf="material?.isAvailable" class="shopping-cart-container-mobile">
                            <div *ngIf="!material?.hasPreorder" class="shopping-cart-mobile">
                                <button *ngIf="material?.quantityOfSupply !== 0" (click)="addToCart(material!)">KOSÁRBA</button>
                                <div *ngIf="material?.quantityOfSupply === 0" class="inactive">
                                    <button class="inactive-button" [ngStyle]="{ opacity: '0.5', cursor: 'auto' }">
                                        {{ 'shoppingCart.putIntoCart' | translate }}
                                    </button>
                                    <span>Elfogyott</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="material?.hasPreorder" class="shopping-cart-mobile">
                            <button (click)="addToCart(material!)" class="preorder-mobile">ELŐRENDELÉS</button>
                        </div>
                    </div>
                </div>

                <div *ngIf="material?.routerLink !== 'morte_taska'" class="parameter-box">
                    <!-- <div *ngIf="material?.hasPreorder" class="preorder-text">
                        <div class="preorder">
                            <p>{{ preOrderData }}</p>
                        </div>
                    </div> -->
                    <div class="param-box">
                        <p *ngIf="material?.serieId !== null">
                            {{ 'materials.serie' | translate }} {{ serie.serieName }} {{ material?.serieNumber }}.
                        </p>
                        <p *ngIf="material?.translator">{{ 'materials.translator' | translate }} {{ material?.translator }}</p>
                        <p *ngIf="material?.illustrator">{{ 'materials.illustrator' | translate }} {{ material?.illustrator }}</p>
                        <br />
                        <p *ngIf="!material?.isAvailable">
                            {{ 'materials.expectedPublicationDate' | translate }} {{ material?.available | date }}
                        </p>
                        <p *ngIf="material?.isAvailable">{{ 'materials.publishedBook' | translate }} {{ material?.available | date }}</p>
                        <p>{{ 'materials.bond' | translate }} {{ material?.bond }}</p>
                        <p>{{ 'materials.volume' | translate }} {{ material?.content }}</p>
                        <p>{{ 'materials.size' | translate }} {{ material?.dimension }}</p>
                        <p>{{ 'materials.isbn' | translate }} {{ material?.isbn }}</p>
                    </div>

                    <div *ngIf="packageOffer.length > 0" routerLink="/specialoffer/{{ packageOffer[0].routerLink }}" class="package-offer">
                        <div class="christmas-text">
                            <h3>Csomagban, kedvezménnyel!</h3>
                        </div>
                        <img src="{{ packageOfferElement.imageUrl }}" />
                        <div [ngStyle]="{ 'padding-top': '16px' }" class="package-name">
                            <b>{{ packageOfferElement.packageName }} csomag</b>
                        </div>
                        <div [ngStyle]="{ 'padding-top': '8px' }" class="package-price">
                            <span>Akciós ár: {{ packagePrice | currency : 'Ft' : 'symbol' : '1.0-0' }} </span>
                        </div>
                    </div>
                </div>
                <div class="text-data-container">
                    <div *ngIf="material?.routerLink !== 'morte_taska'" class="text-data">
                        <div [innerHTML]="material?.txt!"></div>
                    </div>
                    <pw-pressarticle></pw-pressarticle>
                </div>

                <div *ngIf="material?.routerLink === 'morte_taska'" class="parameter-taska-box">
                    <div *ngIf="material?.hasPreorder" class="preorder-text">
                        <div class="preorder">
                            <p>{{ preOrderData }}</p>
                        </div>
                    </div>

                    <div *ngIf="material?.routerLink === 'morte_taska'" class="param-taska-box">
                        <p>{{ 'materials.size' | translate }} {{ material?.dimension }}</p>
                    </div>

                    <div class="text-data-taska-container">
                        <div class="text-taska-data">
                            <p>{{ material?.textData }}</p>
                        </div>
                    </div>

                    <div *ngIf="packageOffer.length > 0" routerLink="/specialoffer/{{ packageOffer[0].routerLink }}" class="package-offer">
                        <div class="christmas-text">
                            <h3>Csomagban, kedvezménnyel!</h3>
                        </div>
                        <img src="{{ packageOfferElement.imageUrl }}" />
                        <div class="package-name">
                            <b>{{ packageOfferElement.packageName }} csomag</b>
                        </div>
                        <div class="package-price">
                            <s>{{ packageOriginalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</s>
                            <span>{{ packagePrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
